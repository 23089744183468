import axios from "axios";
import React, { useState, useRef, useCallback } from "react";
import NumberFormat from "react-number-format";
// import styles from "./OtherNumberFormat.module.scss";
import { API_BASE_URL } from "../../config";
import { debounce, Webloader } from "../../utils";
import { apiCall } from "../../utils/axios";
import { getCookie } from "../../utils";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField";
import Typography  from "@mui/material/Typography";
import InputAdornment from '@mui/material/InputAdornment';
import { Icon } from "@iconify/react/dist/iconify.js";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack"
import { opdValues,maternityOptionValues ,healthCheckUpValues ,hashedMaternityValues, maternityDefaultValues} from "../../constant";
import useSnackBars from "../common/snackbar/SmeSnackbar";
import ShareSuccess from "../modals/QuoteShareModal";
import Grid from "@mui/material/Grid"

const QuoteCard = (props) => {
  var showBufferValue =
    props.addon && props.addon.corporateBuffer === "yes" ? true : false;
    
  const cbufferRef = useRef();
  const maternityRef = useRef();
  const maternityValueRef = useRef();
  const opdRef = useRef();
  const opdValueRef = useRef()
  const healthCheckUpValuesRef = useRef()
  const healthCheckRef = useRef();
  const cBufferValueRef = useRef();
  const networkHospitalRef = useRef();
  const [showCbufferValue, setShowCbufferValue] = useState(showBufferValue);
  const [discountAdded, setDiscountAdded] = useState(props.discountDetails.discount)
  const [loader, setLoader] = useState(false)
  const [showPopper, setShowPopper] = useState(false)
  const voAuthToken = getCookie('vo-auth-token')
  const agent = getCookie('vo-login-id')
  const { addAlert } = useSnackBars()||{}
  const isEmpLess100 = props.totalEmp <= 100 || false


  const plansCheckHandler = async (e) => {
    if (cbufferRef && cbufferRef.current && cbufferRef.current.checked) {
      setShowCbufferValue(true);
    } else setShowCbufferValue(false);

    var data = {
      healthCheckup: {
        status: healthCheckRef && healthCheckRef.current && healthCheckRef.current.checked ? "yes" : "no",
        value: (healthCheckUpValuesRef && healthCheckUpValuesRef.current && parseInt(healthCheckUpValuesRef.current.value)) || 4000
      },
      maternity:{
        status:maternityRef && maternityRef.current && maternityRef.current.checked
        ? "yes"
        : "no",
       "value": ((hashedMaternityValues && maternityValueRef && maternityValueRef.current && hashedMaternityValues[maternityValueRef.current.value])) || { normal: 25000, caesarean: 50000}
      },
      opd: {
        status: (opdRef && opdRef.current &&  opdRef.current.checked) ? "yes" : "no",
        value: (opdValueRef&& opdValueRef.current && parseInt(opdValueRef.current.value)) || 4000,
      },
      corporateBuffer:{
        status:cbufferRef && cbufferRef.current && cbufferRef.current.checked
        ? "yes"
        : "no",
        value:  cBufferValueRef &&
        cBufferValueRef.current &&
        cBufferValueRef.current.value
        ? parseInt(cBufferValueRef.current.value)
        : 100000,
      },
          networkHospitalTreatment: {
            "status": networkHospitalRef && networkHospitalRef.current && networkHospitalRef.current.checked?"yes":"no"
          }
    };

    await apiCall(
      `${API_BASE_URL}/sme-api/lead/${props.leadId}/customized`,
      "POST",
      data
    ).then(() => {
        props.callPremium();
      })
      .catch((error) => { 
        if (error.response && error.response.status === 401) {
          console.error('Bad Request:', error.response.data);
          props.sessionHandler()
        }
        addAlert("Something Went Wrong !!!!")
      });
  };

  const downloadPDf = async () => {
    Webloader("show");
    try{
    const response = await axios.get(
      `${API_BASE_URL}/sme-api/lead/${props.leadId}/pdf?type=${props.type}`,
      {
        headers: {
          user: agent,
          token: voAuthToken
        },
       responseType: "blob",
      }
    );
    const data = response.data;
    const blob = new Blob([data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${props.type}-quote.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
    props.handleSnackBar({display:true, message:'File Successfully Downloaded'})
  }
  catch(error){

    if (error.response && error.response.status === 401) {
      console.error('Bad Request:', error.response.data);
      props.sessionHandler()
    }
    else if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = function () {
        try {
          const jsonError = JSON.parse(reader.result);
          props.handleSnackBar({display:true, message:jsonError?jsonError.message:'Download Failed'})
        } catch (e) {
          console.error('Failed to parse JSON from blob:', e);
        }
      };
      reader.readAsText(error.response.data); // Convert blob to text before parsing JSON
    } else {
      // Handle non-400 or other unexpected errors
      console.error('Error:', error.message);
    }
    props.handleSnackBar({display:true, message:(error?.response?.data?.message)?error.response.data.message:'Download Failed'})
  }
    Webloader();
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(
    //   new Blob([response.data], { type: "application/pdf" })
    // );
    // link.setAttribute("download", `${props.type}-quote.pdf`);
    // document.body.appendChild(link);
    // link.click();
  };

  function isValidRange(value) {
    return typeof value === 'number' && !isNaN(value) && value>=parseFloat(props.discountDetails.minCommission) && value<=parseFloat(props.discountDetails.maxCommission);
  }

  const handleDiscountAPI=async(value)=>{

    if(value===''){
      value=0
    }

    let intValue = parseInt(value)
    
  

    if(!isValidRange(intValue)){
      addAlert("Invalid Discount Value")
      setLoader(false)
      return 
    }

    try{
      const response = await axios.post(
        `${API_BASE_URL}/sme-api/lead/${props.leadId}/discounted`,
        {
          "quoteType": props.type,
          "discount": value,
        }
        ,{
          headers: {
            user: agent,
           token: voAuthToken
          },
        }
      );
      if(response.data.data)
      props.handleDiscountUpdate(response.data.data)
      setLoader(false)
      }
      catch(error){
        if (error.response && error.response.status === 401) {
          console.error('Bad Request:', error.response.data);
          props.sessionHandler()
        }
        setLoader(false)
      }
  }

  const debouncedApiCall = useCallback(debounce(handleDiscountAPI, 700), []);

  const handleDiscountChange = async(value) => {
    if ((value.match(/^\d*\.?\d{0,2}$/) && isValidRange(parseFloat(value)))||value==='') {
    setDiscountAdded(value);
    if(value||value===''){
    setLoader(true)   
    debouncedApiCall(value)  
    }
  }
  }

  const handleEmailShare = async () => {
    Webloader("show");
    try{
    const response = await axios.get(
      `${API_BASE_URL}/sme-api/lead/${props.leadId}/send-email?type=${props.type}`,
      {
        headers: {
          user: agent,
          token: voAuthToken
        },
      }
    )
      setShowPopper(true)
       //props.handleSnackBar({display:true, message:'Email Sent '})
    }
    catch(error){
      if (error.response && error.response.status === 401) {
        console.error('Bad Request:', error.response.data);
        props.sessionHandler()
      }
       props.handleSnackBar({display:true, message:(error?.response?.data?.message)?error.response.data.message:'Unable to send Email'})
    }
    Webloader();
  }

  return (
    <>
    {showPopper && <ShareSuccess handleClose={()=>setShowPopper(false)} />}
    <Box
      marginBottom={'40px'}
      borderRadius={'16px'}
      className="plan-card  bg-white text-center">
      {/* {props.cardTitle.indexOf("Customized") !== -1 ? (
        <div className="rec-card">Plan for you</div>
      ) : null} */}
      <div>
       
        <div className="d-flex align-items-center justify-content-center">
             {props.tag === "Basic" && (
                  <Stack flexDirection={'row'} justifyContent={'center'} alignContent={'center'} padding={'10px'}>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    <img
                      src="/images/basic.svg"
                      className="mt-2"
                      alt="basic"
                      width={'48px'}
                      height={'48px'}
                    />
                  </Stack>
                </Stack>
              )}

              {props.tag === "Maternity" && (
                  <Stack gap={'12px'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} padding={'10px'}>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    <img
                      src="/images/basic.svg"
                      className="mt-2"
                      alt="basic"
                      width={'48px'}
                      height={'48px'}
                    />
                    <Typography fontWeight={600} fontSize={'16px'} color={'#5A6470'}>Base</Typography>
                  </Stack>
                  <Box><Typography fontWeight={300} fontSize={'32px'} color={'#5A6470'}>+</Typography></Box>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    <img
                      src="/images/maternity-icon.svg"
                      className="mt-2"
                      alt="maternity"
                      width={'48px'}
                      height={'48px'}
                    />
                    <Typography fontWeight={600} fontSize={'16px'} color={'#5A6470'}>Maternity</Typography>
                  </Stack>
                </Stack>
              )}
              {props.tag === "Customized" && (
                <Stack gap={'12px'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} padding={'10px'}>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    <img
                      src="/images/basic.svg"
                      className="mt-2 ml-3"
                      alt="basic"
                      width={'48px'}
                      height={'48px'}
                    />
                    {/* <Typography fontWeight={600} fontSize={'16px'} color={'#5A6470'}>Base</Typography> */}
                  </Stack>
                  <Typography fontWeight={600} fontSize={'18px'} color={'#5A6470'}>+</Typography>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    <img
                      src="/images/customized.svg"
                      className="mt-2"
                      alt="customized"
                      width={'48px'}
                      height={'48px'}
                    />
                    {/* <Typography fontWeight={600} fontSize={'16px'} color={'#5A6470'}>Customized</Typography> */}
                  </Stack>
                </Stack>
              )}       
        </div>
        <Typography marginBottom={'10px'}fontWeight={600} fontSize={'16px'} color={'#5A6470'}>Customized Quote</Typography>
        <Divider />
        <Stack padding={'10px 20px'}>
          <Box className="d-flex justify-content-center align-items-center">
            {loader?
            <Box height={42}><Icon icon="eos-icons:three-dots-loading" width="80px" height="42px"  style={{color: 'rgb(54, 73, 138)'}}></Icon> </Box>
           : <h3 className="card-text quote-val">
              <strong>
                <NumberFormat
                  thousandsGroupStyle="lakh"
                  value={props.premium}
                  prefix="₹"
                  suffix="*"
                  decimalSeparator="."
                  displayType="text"
                  type="text"
                  thousandSeparator={true}
                  allowNegative={true}
                />
              </strong>
            </h3>}
            {!loader && <small className="fs-14">/ yr</small>}
          </Box>
          <Typography fontSize={'14px'}>{Math.floor(props.premium / props.totalEmp)}*/ Life </Typography>
        </Stack>
        {/* <p className="card-text quote-val"> */}

        {/* </p> */}
        {/* <div className="px-2 text-center">
          <div className="d-block">
            {props.leadType!=='renewal' && <button
              onClick={downloadPDf}
              className="btn btn-success mb-2 w-100 position-relative"
            >
              {" "}
              Download Quote
            </button>}
          </div>
          {/* <button onClick={downloadPDf} className="btn btn-link p-0 fs-16">
            Download Quote
          </button> 
        </div> */}
      </div>
      <Stack justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} bgcolor={'#E6F2FF'} margin={'10px 20px'} padding={'5px 10px'} borderRadius={'8px'}>
        <Stack alignItems={'start'}>
          <Typography fontWeight={600} fontSize={'14px'}>Discount</Typography>
          <Typography fontWeight={400} fontSize={'12px'} color={'#666666'}>(Range:{props?.discountDetails?.minCommission||0}%-{props?.discountDetails?.maxCommission||0}%)</Typography>
        </Stack>
        <TextField 
          size="small"
          variant="standard"
          sx={{width:'110px',background:'#ffffff', height: '38px',borderRadius:'8px' ,'& input': {
            padding: '10px',
          }}}
          value={discountAdded}
          onChange={(e)=>
              handleDiscountChange(e.target.value)
          }
          InputProps={{
            endAdornment: <InputAdornment position="end" sx={{padding:'8px'}}>%</InputAdornment>,
            disableUnderline: true,
          }}
        />
      </Stack>

      <Grid container padding={'10px 20px'}  justifyContent={'space-between'}>
        {!!props?.discountDetails?.bankCommission && <Grid item xs={6} textAlign={'start'} sx={{ '&:nth-of-type(even)': { textAlign: 'end' } }}>
          <Typography fontSize={'13px'} color={'#808080'}>Bank Commission</Typography>
          <Typography fontSize={'16px'} fontWeight={'600'}>{props?.discountDetails?.bankCommission||0}%</Typography>
        </Grid>}
        <Grid item xs={6} textAlign={'start'} sx={{ '&:nth-of-type(even)': { textAlign: 'end' } }} >
          <Typography textAlign={'13px'} color={'#808080'}>Marketing Fee</Typography>
          {loader?<Box height={24}><Icon icon="eos-icons:three-dots-loading" width="30px" height="24px"  style={{color: 'rgb(54, 73, 138)'}}></Icon></Box>:<Typography fontSize={'16px'} fontWeight={'600'}>{props?.discountDetails?.commission||0}%</Typography>}
        </Grid>
        <Grid item xs={6} textAlign={'start'} sx={{ '&:nth-of-type(even)': { textAlign: 'end' } }}>
          <Typography fontSize={'13px'} color={'#808080'}>My Earnings</Typography>
          {loader?<Box height={24}><Icon icon="eos-icons:three-dots-loading" width="30px" height="24px"  style={{color: 'rgb(54, 73, 138)'}}></Icon></Box>:<Typography fontSize={'16px'} fontWeight={'600'}>₹ {props?.earning||0}</Typography>}
        </Grid>
      </Grid>

      {props.tag === "Customized" ? (
        <>
          <Divider variant="middle" />
          <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} padding={'10px'}>
            <Typography fontSize={'13px'} color={'#808080'} textTransform={'uppercase'}>Customize with following</Typography>
          </Stack>
          <Box padding={'10px 20px'} className="text-left">
            <Stack className="quote-checkbx" gap={'12px'} padding={'10px 0px'}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
              <input
                type="checkbox"
                name="maternity"
                value="yes"
                id="maternity"
                onChange={plansCheckHandler}
                ref={maternityRef}
                defaultChecked={props?.addon?.maternity === "yes" ? true : false}
              />
              <label
                className="form-check-label border-0 fs-14"
                htmlFor="maternity"
                style={{fontWeight:'500'}}
              >
                Maternity
              </label>
              </Stack>
              {props?.addon?.maternity === "yes"  && <select
                className="formoutlin-select  d-block col-12 custom-select "
                aria-label="Maternity"
                ref={maternityValueRef}
                onChange={plansCheckHandler}
                defaultValue={props?.addon?.maternityNormalValue?maternityDefaultValues[props?.addon?.maternityNormalValue]:'Normal – ₹ 25,000, Caesarean – ₹ 50,000'}
              >
                {maternityOptionValues.map((each,index)=>{return <option key={index} value={each}> {each}</option>})}
              </select>}
            </Stack>
            {props.is2LakhSI && <>
            <Stack className="quote-checkbx" gap={'12px'}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
              <input
                type="checkbox"
                name="cBuffer"
                value="yes"
                id="cBuffer"
                onChange={plansCheckHandler}
                ref={cbufferRef}
                disabled={isEmpLess100}
                defaultChecked={props?.addon?.corporateBuffer === "yes" && (!isEmpLess100) ? true : false}
              />
              <label
                className="form-check-label border-0 fs-14"
                htmlFor="cBuffer"
                style={{fontWeight:'500'}}
              >
                Corporate Buffer
              </label>
              </Stack>
          
            {showCbufferValue && (
              <select
                className="formoutlin-select d-block col-12 custom-select "
                aria-label="Corporate Buffer"
                ref={cBufferValueRef}
                onChange={plansCheckHandler}
                defaultValue={props?.addon?.corporateBufferValue||100000}
                disabled={isEmpLess100}
              >
                <option value={100000}>₹1 lakh</option>
                <option value={200000}>₹2 lakhs</option>
                <option value={300000}>₹3 lakhs</option>
                <option value={400000}>₹4 lakhs</option>
                <option value={500000}>₹5 lakhs</option>
                <option value={600000}>₹6 lakhs</option>
                <option value={700000}>₹7 lakhs</option>
                <option value={800000}>₹8 lakhs</option>
                <option value={900000}>₹9 lakhs</option>
                <option value={1000000}>₹10 lakhs</option>
              </select>
            )}
              </Stack>
            <Stack padding={'10px 0px'} gap={'12px'}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
              <input
                type="checkbox"
                name="opd"
                value="yes"
                id="opd"
                onChange={plansCheckHandler}
                ref={opdRef}
                defaultChecked={props?.addon?.opd === "yes" ? true : false}
              />
              <label className="form-check-label border-0 fs-14" htmlFor="opd" style={{fontWeight:'500'}}>
                Outpatient treatment(OPD)
              </label>
              </Stack>
              {props?.addon?.opd === "yes" && <select
                className="formoutlin-select d-block col-12 custom-select "
                aria-label="Corporate Buffer"
                ref={opdValueRef}
                onChange={plansCheckHandler}
                defaultValue={props?.addon?.opdValue||4000}
              >
                {opdValues.map((each,index)=>{return <option key={index} value={each}>₹ {each}</option>})}
              </select>}
            </Stack>
            <Stack className="quote-checkbx" gap={'12px'}>
              <Stack gap={'4px'} flexDirection={'row'} alignItems={'center'}>
              <input
                type="checkbox"
                name="healthcheck"
                value="yes"
                id="healthcheck"
                onChange={plansCheckHandler}
                ref={healthCheckRef}
                defaultChecked={
                  props?.addon?.healthCheckup === "yes" ? true : false
                }
              />
              <label
                className="form-check-label border-0 fs-14"
                htmlFor="healthcheck"
                style={{fontWeight:'500'}}
              >
                Health Check up
              </label>
              </Stack>
              {props?.addon?.healthCheckup === "yes" && <select
                className="formoutlin-select  d-block col-12 custom-select "
                aria-label="Health Check Up"
                ref={healthCheckUpValuesRef}
                onChange={plansCheckHandler}
                defaultValue={props?.addon?.healthCheckupValue||3500}
              >
                {healthCheckUpValues.map((each,index)=>{return <option className='custom-option-mui' key={index} value={each}>₹ {each}</option>})}
              </select>}
            </Stack>
            </>}
            <Stack className="quote-checkbx" flexDirection={'row'} alignItems={'center'} padding={'10px 0px'} gap={'4px'}>
              <input
                type="checkbox"
                name="netWorkTreatment"
                value="yes"
                id="netWorkTreatment"
                onChange={plansCheckHandler}
                ref={networkHospitalRef}
                defaultChecked={props?.addon?.networkHospitalTreatment === "yes" ? true : false}
              />
              <label
                className="form-check-label border-0 fs-14"
                htmlFor="netWorkTreatment"
                style={{fontWeight:'500'}}
              >
                Network Hospital Treatment
              </label>
            </Stack>
            
          </Box></>
      ) : null}
     <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} padding={'10px 20px'} gap="12px">
        <Button  onClick={handleEmailShare} sx={{ width: '-webkit-fill-available', height: '43px' }} variant="contained" startIcon={<Icon icon="ic:outline-share" width="20px" height="20px" style={{ color: '#ffffff' }} />}>Share Quote</Button>
        <Button  onClick={downloadPDf} sx={{ width: '43px', height: '43px', minWidth: '0px', padding: '5px' }} variant="outlined"><Icon icon="humbleicons:download-alt" width="20px" height="20px" fontWeight={700} /></Button>
      </Stack>
      <Box>
        <Stack
          bgcolor='#E6F2FF'
          bottom={0}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'50px'}
          sx={{ borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px' }}
        >
          <Tooltip arrow={true} placement="right-start" title={(props.leadType==='market_renewal' || props.leadType==='star_renewal')?'All the terms and conditions applicable will be carried forward from previous expiring policy.':''}>
          <Button variant={'link'} sx={{ color: "#3194FD", fontSize: '13px' }} endIcon={<Icon icon="mdi:keyboard-arrow-right" width="20px" height="20px" style={{ color: '#3194FD' }} />} onClick={() => {
            if(props.leadType!=='market_renewal' && props.leadType!=='star_renewal'){
            props.showBenefits({
              isMaternity:props.tag === "Maternity",
              isCBuffer:(cbufferRef && cbufferRef.current && cbufferRef.current.checked)?true:false,
              isOPD:(opdRef && opdRef.current && opdRef.current.checked)?true:false,
              isHealth:(healthCheckRef && healthCheckRef.current && healthCheckRef.current.checked)?true:false,
              isOnAddOnMaternity:(maternityRef && maternityRef.current && maternityRef.current.checked)?true:false,
            })
            }
             return  
            }}>{(props.leadType!=='market_renewal'&&props.leadType!=='star_renewal')? 'View all benefits':'Benefits'}
          </Button>
          </Tooltip>
        </Stack>
      </Box>
    </Box>
    </>
  );
};

export default QuoteCard;
