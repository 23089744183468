import React, { useState, useEffect, useMemo, useRef } from "react";
import { Modal } from "react-responsive-modal";
import axios from "axios";
import dayjs from "dayjs";
import { parseISO } from "date-fns";
import { Webloader, getCookie } from "../../utils";
import { API_BASE_URL } from "../../config";
import LeadCardContainer from "./LeadCardsContainer.tsx";
import { Icon } from '@iconify/react';
import { FormControl, InputLabel, Input, InputAdornment, TextField, Select, MenuItem, Button, Unstable_Grid2, Stack , styled, Skeleton} from '@mui/material'
import useDebounce from "../common/hooks/useDebounce";
import { DASBHBOARD_BLUE_COLOR, PolicyType, PolicyTypeLabel, SORTBYLEADS, SortByLeadsLabels } from "./dashboard.constants.ts";
import SmePaginate from "./SmePaginate.tsx";
import { useNavigate } from "react-router-dom";
import useSnackBars from "../common/snackbar/SmeSnackbar.js";

const SmeSelectLabelStyles=styled(InputLabel)({
  fontSize: '16px',
  paddingTop: '6px',
  marginTop: '-3px'
});


const Dashboard = ({ sessionHandler }) => {

  const {addAlert}=useSnackBars() || {};
  const [fromDate, setFromDate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1); // Subtract 3 months
    return date.toISOString();
  });
  const [toDate,setToDate] = useState(new Date().toISOString())

  useEffect(() => {
    if (new Date(fromDate) > new Date(toDate)) {
      setFromDate(toDate);
    }
  }, [toDate]);

  const ageGroup = {
    '18': '(18-35)',
    '36': '(36-45)',
    '46': '(46-55)',
    '56': '(56-65)',
  }

  const voAuthToken = getCookie('vo-auth-token')
  const agent = getCookie('vo-login-id')
  const strDate = parseISO(
    dayjs().subtract(8, "day").format("YYYY-MM-DDTHH:mm:ss")
  );
  const enDate = parseISO(
    dayjs().subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss")
  );

  const strDateRange = parseISO(
    dayjs().subtract(3, "month").format("YYYY-MM-DDTHH:mm:ss")
  );

  const enDateRange = parseISO(
    dayjs().subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss")
  );

  const [startDate, setStartDate] = useState(strDate);
  const [endDate, setEndDate] = useState(enDate);
  const [jsonData, setJsonData] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const view = "/images/view.png";
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounce({ value: searchValue, delay: 2000 })
  const userTypeRef = useRef();
  const sortByRef = useRef();
  const [leads, setLeads] = useState([]);
  const [loadingLeads, setLoadingLeads] = useState(false);
  const [pageAvailable, setPageAvailable] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const navigate = useNavigate();

  const JSONView = (item) => {
    setModalStatus(true);
    setJsonData(item);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Employer Details",
        accessor: "userDetail",
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Sales Zones",
        accessor: "salesZone",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Family Details",
        accessor: "familyDetails",
      },
      {
        Header: "Total Premium",
        accessor: "totalPremium",
      },
    ],
    []
  );

  const getLeadUrl = (type, pageNumber, userType, sortBy) => {

    let user = userType ? (userType === 'All' ? '' : userType) : (userTypeRef?.current?.value === 'All' ? '' : userTypeRef?.current?.value);

    let companySearch = debounceSearchValue;
    // let leadType =
    //   leadTypeRef && leadTypeRef.current && leadTypeRef.current.value
    //     ? leadTypeRef.current.value
    //     : "";
    let filterStartDate = dayjs(startDate).format("YYYY-MM-DD");
    let filterEndDate = dayjs(endDate).format("YYYY-MM-DD");

    // if (userInfo.role === "admin" && userInfo.userType === "banca") {
    //   userType = userInfo.userType;
    // }

    // if (userInfo.role === "aduserInfomin" && userInfo.userType === "partners") {
    //   userType = userInfo.userType;
    // }

    // if (userInfo.role === "admin" && userInfo.userType === "user") {
    //   userType = userInfo.userType;
    // }

    if (type === "getLeads") {
      return `${API_BASE_URL}/sme-api/dashboard/leads?company=${companySearch}&policyType=${user ?? userTypeRef.current.value}&sortBy=${sortBy ?? sortByRef.current.value}&offset=${pageNumber
        }&limit=${pageSize}`;
    } else if (type === "downloadLeads") {
        return `${API_BASE_URL}/sme-api/dashboard/leads/download?company=${companySearch}&policyType=${user}&sortBy=${sortBy}`;
    }
  };

  const getLeads = async (pageNumber, userType, sortBy) => {
    setLoadingLeads(true);
    let leadUrl = await getLeadUrl("getLeads", pageNumber ?? currentPage, userType, sortBy);
    console.log(leadUrl,'url is here')
    axios
      .get(leadUrl, {
        headers: {
          user: agent,
          token: voAuthToken,
        },
      })?.then((response) => {
        if (Object.keys(response.data.data).length > 0) {
          if (response?.data && response?.data?.data?.leads?.length) {
            const data = response.data.data.leads;
            if (pageAvailable !== response.data.data.totalPages) {
              setPageAvailable(response.data.data.totalPages);
            }
            setCurrentPage(response.data.data.page);
            setLeads((prevLeads) => [...data]);
          } else {
            setLeads([])
          };
          setLoadingLeads(false);
        }
      })
      .catch((error) => {
        setLoadingLeads(false);
        if (error.response && error.response.status === 401) {
          console.error('Bad Request:', error.response.data);
          sessionHandler()
        }
      });
  };

  useEffect(() => {
    getLeads(1, '', SORTBYLEADS.DES_DATE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (debounceSearchValue === '') {
      getLeads(1)
    } else if (debounceSearchValue.trim() !== '') {
      getLeads(1);
    }
  }, [debounceSearchValue])

  const downloadExcelHandler = async () => {
    let downloadUrl = await getLeadUrl("downloadLeads");
    Webloader("show");
    try{
      const response = await axios.get(`${downloadUrl}&fromDate=${fromDate}&toDate=${toDate}`, {
        headers: {
          user: agent,
          token: voAuthToken
        },
        responseType: "blob",
      });
      Webloader();
      console.log(response);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", `leads.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
        console.log(e.response, e);
        if(e.response.status === 500) {
          Webloader();
          addAlert('Internal Server Error (500)')
        }
    }  
  };

  const showForamttedHead = (value) => {
    if (value === 'child') return value.toUpperCase()
    else {
      const ageString = ageGroup[value.slice(value.length - 2)]
      const sectionString = value.slice(0, value.length - 2)
      return sectionString.toUpperCase() + ' ' + ageString
    }
  }

  const onPageChangeEvent = (updatedPageNumber) => {
    getLeads(updatedPageNumber)
  }

  const getMinDate=()=>{
    const date = new Date(toDate);
    date.setMonth(date.getMonth() - 1); // Subtract 3 months
    return date.toISOString().split('T')[0];
  }

  const userType = sessionStorage.userType;

  return (
    <div className="container container1">
      <Unstable_Grid2 container spacing={2} sx={{ marginTop: '30px' }}>
        <Unstable_Grid2 xs={12} sm={12} md={3}>
          <TextField
            sx={{ backgroundColor: '#fff' }}
            id="Company_search"
            variant='outlined'
            label={'Company Search'}
            fullWidth={true}
            onChange={(e) => setSearchValue(e.currentTarget.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon icon={'hugeicons:search-01'}></Icon>
                </InputAdornment>
              )
            }}

          />
        </Unstable_Grid2>
        <Unstable_Grid2 xs={6} sm={6} md={2}>
          <FormControl fullWidth size="small">
            <SmeSelectLabelStyles id="Quote_type-label">Quote Type</SmeSelectLabelStyles>
            <Select
              sx={{
                backgroundColor: '#fff', 
              }}
              labelId="Quote_type_label"
              id="quote_type"
              inputRef={userTypeRef}
              IconComponent={() => <Icon height={'1.2em'} width={'1.2em'} style={{ position: 'absolute', right: '8px', pointerEvents: 'none', color: '#808080' }} icon="mdi:keyboard-arrow-down" />}
              label="Quote Type"
              defaultValue={'All'}
              onChange={(e) => {
                getLeads(1, e.target.value, sortByRef.current.value)
              }}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={PolicyType.MARKET_RENEWAL}>{PolicyTypeLabel[PolicyType.MARKET_RENEWAL]}</MenuItem>
              <MenuItem value={PolicyType.FRESH}>{PolicyTypeLabel[PolicyType.FRESH]}</MenuItem>
              <MenuItem value={PolicyType.STAR_RENEWAL}>{PolicyTypeLabel[PolicyType.STAR_RENEWAL]}</MenuItem> 
            </Select>
          </FormControl>
        </Unstable_Grid2>
        <Unstable_Grid2 sx={{ display: 'flex', justifyContent: 'end' }} xs={6} sm={6} md={7}>
          <Button
            variant="outlined"
            color="primary"
            size="md" sx={{ padding: '5px 20px', marginLeft: '5px' }}
            onClick={() => navigate('/company-details')}
          >
            <Icon icon={'mdi:plus'}></Icon> &nbsp; Create Lead
          </Button>
          </Unstable_Grid2>
      </Unstable_Grid2>
      <Unstable_Grid2 container spacing={2} marginTop={'10px'} marginBottom={'10px'}>
        <Unstable_Grid2 xs={6} sm={6} md={2}>
          <InputLabel sx={{ fontSize: '12px', margin: '0' }}>Sort By</InputLabel>
          <FormControl fullWidth size="small">
            <Select
              variant="standard"
              inputRef={sortByRef}
              id="sort_by"
              disableUnderline
              IconComponent={() => <Icon height={'1.5em'} width={'1.5em'} style={{ position: 'absolute', right: '0', pointerEvents: 'none', }} icon="mdi:keyboard-arrow-down" />}
              sx={{ color: (theme) => `${theme.palette.primary.main}`, fontSize: '13px' }}
              defaultValue={SORTBYLEADS.DES_DATE}
              onChange={e => {
                getLeads(1, userTypeRef.current.value, e.target.value)
              }}
            >
              <MenuItem value={SORTBYLEADS.ASC_DATE}>{SortByLeadsLabels[SORTBYLEADS.ASC_DATE]}</MenuItem>
              <MenuItem value={SORTBYLEADS.DES_DATE}>{SortByLeadsLabels[SORTBYLEADS.DES_DATE]}</MenuItem>
            </Select>
          </FormControl>
        </Unstable_Grid2>
        <Unstable_Grid2 sx={{ display: 'flex', justifyContent: 'end' ,flexWrap:'wrap'}} xs={12} sm={12} md={10}>
          {leads && leads.length ? (
            <>
            <Stack gap={'10px'} flexDirection={'row'}>
                <TextField
                      fullWidth
                      size="small"
                      label="From Date"
                      variant="outlined"
                      placeholder="MM/DD/YY"
                      type="date"
                      sx={{background:'#ffffff'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onFocus={(e) => e.target.blur()} // Prevent focusing to avoid typing
                      onMouseDown={(e) => e.preventDefault()} 
                      inputProps={{
                        min: getMinDate(),
                        max: new Date(toDate).toISOString().split('T')[0], 
                      }}
                      onChange={(e)=>setFromDate(new Date(e.target.value).toISOString())}
                     value={fromDate.split('T')[0]}
                    />
                     <TextField
                      fullWidth
                      size="small"
                      label="To Date"
                      variant="outlined"
                      placeholder="MM/DD/YY"
                      sx={{background:'#ffffff'}}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().split('T')[0], 
                      }}
                      onFocus={(e) => e.target.blur()} // Prevent focusing to avoid typing
                      onMouseDown={(e) => e.preventDefault()} 
                      value={toDate.split('T')[0]}
                      onChange={(e)=>setToDate(new Date(e.target.value).toISOString())}
                    />
                </Stack>
            <Button
              variant="text"
              color="primary"
              size="md" sx={{ padding: '5px 10px', marginLeft: '5px' }}
              onClick={downloadExcelHandler}
            >
              Download
            </Button>
            </>
          ) : null}
          {/* <Button
            variant="outlined"
            color="primary"
            size="md" sx={{ padding: '5px 20px', marginLeft: '5px' }}
            onClick={() => navigate('/company-details')}
          >
            <Icon icon={'mdi:plus'}></Icon> &nbsp; Create Lead
          </Button> */}
        </Unstable_Grid2>
        </Unstable_Grid2>

      <Stack container spacing={3} marginBottom={'15px'} alignItems={'center'}>
        {loadingLeads ? <><Skeleton height={280} animation='wave' width={'100%'} variant="rectangular"></Skeleton>
        <Skeleton height={280} animation='wave' width={'100%'} variant="rectangular"></Skeleton>
        <Skeleton height={280} width={'100%'} variant="rectangular"></Skeleton></>
        :<LeadCardContainer leadData={leads} />}
        {!!leads.length && <SmePaginate page={+currentPage} totalPages={+pageAvailable} onPageChange={onPageChangeEvent} />}
      </Stack>
      {modalStatus && (
        <Modal
          showCloseIcon={true}
          open={modalStatus}
          onClose={() => {
            setModalStatus(false);
          }}
          center
        >
          {jsonData ? (
            <div className="mt-5">
              <table className="table text-center table-striped table-bordered p-4 border-0">
                <tr className="mt-5">
                  {Object.keys(jsonData).map((premium, index) => {
                    return (<>
                      {jsonData[premium] !== "" ? (

                        <th>{showForamttedHead(premium)}</th>

                      ) : null}
                    </>
                    );
                  })}
                </tr>
                <tr>
                  {Object.keys(jsonData).map((premium, index) => {
                    return (
                      <>
                        {jsonData[premium] !== "" ? (
                          <td>{jsonData[premium]}</td>
                        ) : null}
                      </>
                    );
                  })}
                </tr>
              </table>
            </div>
          ) : null}
        </Modal>
      )}
    </div>
  );
};

export default Dashboard;
