import axios from "axios";

export class CompanyDetailApi{
    static baseUrl=process.env.REACT_APP_API_BASE_URL;
     

    static getCompanyList=(searchString='company',query='',queryType='infix', token, agent)=>{
       
        const url=`${CompanyDetailApi.baseUrl}/api/search/${searchString}`
        return axios.get(url, {
        
        params: {
            query:query,
            queryType:queryType
        }
      })
    }
}