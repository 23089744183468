import React,{useState,useCallback,useRef} from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react/dist/iconify.js";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from "@mui/material/Tooltip";
import { debounce, Webloader } from "../../utils";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import useSnackBars from "../common/snackbar/SmeSnackbar";
import { getCookie } from "../../utils";
import ShareSuccess from "../modals/QuoteShareModal";
import { apiCall } from "../../utils/axios";
import { opdValues, maternityOptionValues, healthCheckUpValues, hashedMaternityValues, maternityDefaultValues ,roomRentvalues,coPayValues,NetworkHospitalTreatment} from "../../constant";

const NewQuoteCard = (props) => {

    var showBufferValue =
     props.addon && props.addon.corporateBuffer === "yes" ? true : false;

    const voAuthToken = getCookie('vo-auth-token')
    const agent = getCookie('vo-login-id')
    const isEmpLess100 = props.totalEmp <= 100 || false
    const { addAlert } = useSnackBars()||{}
    
    const [discountAdded, setDiscountAdded] = useState(props?.discountDetails?.discount||0)
    const [showPopper, setShowPopper] = useState(false)
    const [loader, setLoader] = useState(false)
    const [showCbufferValue, setShowCbufferValue] = useState(showBufferValue);
    
    const maternityRef = useRef();
    const cbufferRef = useRef();
    const maternityValueRef = useRef();
    const opdRef = useRef();
    const opdValueRef = useRef()
    const healthCheckUpValuesRef = useRef()
    const healthCheckRef = useRef();
    const coPayRef = useRef()
    const coPayRefValues = useRef()
    const roomRentRef = useRef();
    const roomRentValueRef = useRef();
    const cBufferValueRef = useRef();
    const networkHospitalRef1 = useRef();
    const networkHospitalRef2 = useRef();
    const networkHospitalRef3 = useRef();

    const downloadPDf = async () => {
        Webloader("show");
        try{
        const response = await axios.get(
          `${API_BASE_URL}/sme-api/lead/${props.leadId}/pdf?type=${props.type}`,
          {
            headers: {
              user: agent,
              token: voAuthToken
            },
           responseType: "blob",
          }
        );
        const data = response.data;
        const blob = new Blob([data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${props.type}-quote.pdf`;
        link.click();
        window.URL.revokeObjectURL(link.href);
        props.handleSnackBar({display:true, message:'File Successfully Downloaded'})
      }
      catch(error){
    
        if (error.response && error.response.status === 401) {
          console.error('Bad Request:', error.response.data);
          props.sessionHandler()
        }
        else if (error.response && error.response.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = function () {
            try {
              const jsonError = JSON.parse(reader.result);
              props.handleSnackBar({display:true, message:jsonError?jsonError.message:'Download Failed'})
            } catch (e) {
              console.error('Failed to parse JSON from blob:', e);
            }
          };
          reader.readAsText(error.response.data); // Convert blob to text before parsing JSON
        } else {
          // Handle non-400 or other unexpected errors
          console.error('Error:', error.message);
        }
        props.handleSnackBar({display:true, message:(error?.response?.data?.message)?error.response.data.message:'Download Failed'})
      }
        Webloader();
        // const link = document.createElement("a");
        // link.href = URL.createObjectURL(
        //   new Blob([response.data], { type: "application/pdf" })
        // );
        // link.setAttribute("download", `${props.type}-quote.pdf`);
        // document.body.appendChild(link);
        // link.click();
      };
    
    function isValidRange(value) {
        return typeof value === 'number' && !isNaN(value) && value>=parseFloat(props.discountDetails.minCommission) && value<=parseFloat(props.discountDetails.maxCommission);
    }

    const handleDiscountAPI=async(value)=>{
        if(value===''){
          value=0
        }
        let intValue = parseInt(value)
        if(!isValidRange(intValue)){
          addAlert("Invalid Discount Value")
          setLoader(false)
          return 
        }
        try{
          const response = await axios.post(
            `${API_BASE_URL}/sme-api/lead/${props.leadId}/discounted`,
            {
              "quoteType": props.type,
              "discount": value,
            }
            ,{
              headers: {
                user: agent,
               token: voAuthToken
              },
            }
          );
          if(response.data.data)
          props.handleDiscountUpdate(response.data.data)
          setLoader(false)
          }
          catch(error){
            if (error.response && error.response.status === 401) {
              console.error('Bad Request:', error.response.data);
              props.sessionHandler()
            }
            setLoader(false)
          }
      }   
      
    const debouncedApiCall = useCallback(debounce(handleDiscountAPI, 700), []); 

    const handleDiscountChange = async(value) => {
        if ((value.match(/^\d*\.?\d{0,2}$/) && isValidRange(parseFloat(value)))||value==='') {
        setDiscountAdded(value);
        if(value||value===''){
        setLoader(true)   
        debouncedApiCall(value)  
        }
      }
    }
    
    const handleEmailShare = async () => {
        Webloader("show");
        try{
        const response = await axios.get(
          `${API_BASE_URL}/sme-api/lead/${props.leadId}/send-email?type=${props.type}`,
          {
            headers: {
              user: agent,
              token: voAuthToken
            },
          }
        )
          setShowPopper(true)
           //props.handleSnackBar({display:true, message:'Email Sent '})
        }
        catch(error){
          if (error.response && error.response.status === 401) {
            console.error('Bad Request:', error.response.data);
            props.sessionHandler()
          }
           props.handleSnackBar({display:true, message:(error?.response?.data?.message)?error.response.data.message:'Unable to send Email'})
        }
        Webloader();
      }

     function getNetWorkValue () {
        if(networkHospitalRef1 && networkHospitalRef1.current && networkHospitalRef1.current.checked){
            return networkHospitalRef1.current && networkHospitalRef1.current.value
        }
        else if(networkHospitalRef2 && networkHospitalRef2.current && networkHospitalRef2.current.checked){
            return networkHospitalRef2.current && networkHospitalRef2.current.value
        }
        else  if(networkHospitalRef3 && networkHospitalRef3.current && networkHospitalRef3.current.checked){
            return networkHospitalRef3.current && networkHospitalRef3.current.value
        }
     }

    const plansCheckHandler = async (e) => {
        if (cbufferRef && cbufferRef.current && cbufferRef.current.checked) {
          setShowCbufferValue(true);
        } else setShowCbufferValue(false);

        var data = {
          healthCheckup: {
            status: healthCheckRef && healthCheckRef.current && healthCheckRef.current.checked ? "yes" : "no",
            value: (healthCheckUpValuesRef && healthCheckUpValuesRef.current && parseInt(healthCheckUpValuesRef.current.value)) || 4000
          },
          maternity:{
            status:maternityRef && maternityRef.current && maternityRef.current.checked
            ? "yes"
            : "no",
           "value": ((hashedMaternityValues && maternityValueRef && maternityValueRef.current && hashedMaternityValues[maternityValueRef.current.value])) || { normal: 25000, caesarean: 50000}
          },
          opd: {
            status: (opdRef && opdRef.current &&  opdRef.current.checked) ? "yes" : "no",
            value: (opdValueRef&& opdValueRef.current && parseInt(opdValueRef.current.value)) || 4000,
          },
            coPay: {
                status:coPayRef && coPayRef.current && coPayRef.current.checked
                ? "yes"
                : "no",
                value: (coPayRefValues&& coPayRefValues.current && parseInt(coPayRefValues.current.value)) || 10,
            },
            roomRent: {
                status:roomRentRef && roomRentRef.current && roomRentRef.current.checked
                ? "yes"
                : "no",
                value: (roomRentValueRef&& roomRentValueRef.current && parseInt(roomRentValueRef.current.value)) || 15,
            },
          corporateBuffer:{
            status:cbufferRef && cbufferRef.current && cbufferRef.current.checked
            ? "yes"
            : "no",
            value:  cBufferValueRef &&
            cBufferValueRef.current &&
            cBufferValueRef.current.value
            ? parseInt(cBufferValueRef.current.value)
            : 100000,
          },
          networkHospitalTreatment: getNetWorkValue()
        };
        setLoader(true)
        await apiCall(
          `${API_BASE_URL}/sme-api/lead/${props.leadId}/customized`,
          "POST",
          data
        ).then(() => {
            props.callPremium();
            setLoader(false)
          })
          .catch((error) => { 
            if (error.response && error.response.status === 401) {
              console.error('Bad Request:', error.response.data);
              props.sessionHandler()
            }
            setLoader(false)
            addAlert("Something Went Wrong !!!!")
          });
    };

    const policyTypes = {
        market_renewal:'Market Renewal',
        star_renewal:'Star Renewal',
        fresh:'Basic'
    }
    
    const getTopCardHeader = ()=>{
        return (props?.policyType)?policyTypes[props.policyType]:''
    }


    const customAddition = () => {
            if(props?.addon?.corporateBuffer==='yes'||
                props?.addon?.opd==='yes'||
                props?.addon?.healthCheckup==='yes'||
                props?.addon?.roomRent==='yes'||
                props?.addon?.coPay==='yes'
            )
                return ' + Customized'
            else if(props?.addon?.maternity === "yes")
               return  ' + Maternity'
            else
                return ''
    }

    return <>
    {showPopper && <ShareSuccess handleClose={()=>setShowPopper(false)} />}
    <Grid container spacing={4}
        sx={{
            flexDirection: {
                xs: 'column-reverse', 
                md: 'row',        
            },
            justifyContent:'center'
        }}
    >
        {props.type==='customized' && <Grid item xs={12} md={6}>
            <Box
                sx={{
                    background: '#ffffff',
                    border: '2px solid #36498A',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 6px -2px #10182808',

                }}
            >
                <Typography padding={'10px'} color={'#344054'} fontWeight={'600'}> {getTopCardHeader()}{customAddition()}</Typography>
                <Divider color={'#36498A'} sx={{ height: '1px' }} />
                <Box padding={'10px'}>
                    <Stack flexDirection={'row'} justifyContent={'space-between'} className="quote-checkbx" gap={'12px'} padding={'10px 0px'} minHeight={'50px'}>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'} width={'fit-content'}>
                            <input
                                type="checkbox"
                                name="maternity"
                                value="yes"
                                id="maternity"
                                onChange={plansCheckHandler}
                                ref={maternityRef}
                                defaultChecked={props?.addon?.maternity === "yes" ? true : false}
                            />
                            <label
                                className="form-check-label border-0 fs-14"
                                htmlFor="maternity"
                                style={{ fontWeight: '550' ,color:'#344054'}}
                            >
                                Maternity
                            </label>
                        </Stack>
                        {props?.addon?.maternity === "yes" && 
                        <select
                            className="formoutlin-select col-6 custom-select "
                            aria-label="Maternity"
                            ref={maternityValueRef}
                            onChange={plansCheckHandler}
                            defaultValue={props?.addon?.maternityNormalValue?maternityDefaultValues[props?.addon?.maternityNormalValue]:'Normal – ₹ 25,000, Caesarean – ₹ 50,000'}
                        >
                            {maternityOptionValues.map((each, index) => { return <option key={index} value={each}> {each}</option> })}
                        </select>
                        }
                    </Stack>
                    {props.is2LakhSI && 
                    <>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} className="quote-checkbx" gap={'12px'} minHeight={'50px'} padding={'10px 0px'}>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
                                <input
                                    type="checkbox"
                                    name="cBuffer"
                                    value="yes"
                                    id="cBuffer"
                                    onChange={plansCheckHandler}
                                    ref={cbufferRef}
                                    disabled={isEmpLess100}
                                    defaultChecked={props?.addon?.corporateBuffer === "yes" && (!isEmpLess100) ? true : false}
                                />
                                <label
                                    className="form-check-label border-0 fs-14"
                                    htmlFor="cBuffer"
                                    style={{ fontWeight: '550' ,color:'#344054'}}
                                >
                                    Corporate Buffer
                                </label>
                            </Stack>

                            {props?.addon?.corporateBuffer === "yes" &&   (
                                <select
                                    className="formoutlin-select d-block col-6 custom-select "
                                    aria-label="Corporate Buffer"
                                    ref={cBufferValueRef}
                                    onChange={plansCheckHandler}
                                    defaultValue={props?.addon?.corporateBufferValue||100000}
                                    disabled={isEmpLess100}
                                >
                                    <option value={100000}>₹1 lakh</option>
                                    <option value={200000}>₹2 lakhs</option>
                                    <option value={300000}>₹3 lakhs</option>
                                    <option value={400000}>₹4 lakhs</option>
                                    <option value={500000}>₹5 lakhs</option>
                                    <option value={600000}>₹6 lakhs</option>
                                    <option value={700000}>₹7 lakhs</option>
                                    <option value={800000}>₹8 lakhs</option>
                                    <option value={900000}>₹9 lakhs</option>
                                    <option value={1000000}>₹10 lakhs</option>
                                </select>
                            )}
                        </Stack>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} padding={'10px 0px'} gap={'12px'} minHeight={'50px'}>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
                                <input
                                    type="checkbox"
                                    name="opd"
                                    value="yes"
                                    id="opd"
                                    style={{ fontWeight: '550' ,color:'#344054'}}
                                    onChange={plansCheckHandler}
                                    ref={opdRef}
                                    defaultChecked={props?.addon?.opd === "yes" ? true : false}
                                />
                                <label className="form-check-label border-0 fs-14" htmlFor="opd" style={{ fontWeight: '500' }}>
                                    Outpatient Treatment(OPD)
                                </label>
                            </Stack>
                            {props?.addon?.opd === "yes" && 
                            <select
                                className="formoutlin-select d-block col-6 custom-select "
                                aria-label="Corporate Buffer"
                                ref={opdValueRef}
                                onChange={plansCheckHandler}
                                defaultValue={props?.addon?.opdValue||4000}
                            >
                                {opdValues.map((each, index) => { return <option key={index} value={each}>₹ {each}</option> })}
                            </select>
                            }
                        </Stack>
                        <Stack className="quote-checkbx" gap={'12px'} flexDirection={'row'} justifyContent={'space-between'} minHeight={'50px'} padding={'10px 0px'}>
                            <Stack gap={'4px'} flexDirection={'row'} alignItems={'center'}>
                                <input
                                    type="checkbox"
                                    name="healthcheck"
                                    value="yes"
                                    id="healthcheck"
                                    onChange={plansCheckHandler}
                                    ref={healthCheckRef}
                                    defaultChecked={
                                    props?.addon?.healthCheckup === "yes" ? true : false
                                    }
                                />
                                <label
                                    className="form-check-label border-0 fs-14"
                                    htmlFor="healthcheck"
                                    style={{ fontWeight: '550' ,color:'#344054'}}
                                >
                                    Health Check-up
                                </label>
                            </Stack>
                            {props?.addon?.healthCheckup === "yes" && 
                            <select
                                className="formoutlin-select  d-block col-6 custom-select "
                                aria-label="Health Check Up"
                                ref={healthCheckUpValuesRef}
                                onChange={plansCheckHandler}
                                defaultValue={props?.addon?.healthCheckupValue||3500}
                            >
                                {healthCheckUpValues.map((each, index) => { return <option className='custom-option-mui' key={index} value={each}>₹ {each}</option> })}
                            </select>
                            }
                        </Stack>
                        <Stack className="quote-checkbx" gap={'12px'} flexDirection={'row'} justifyContent={'space-between'} minHeight={'50px'} padding={'10px 0px'}>
                            <Stack gap={'4px'} flexDirection={'row'} alignItems={'center'}>
                                <input
                                    type="checkbox"
                                    name="roomRent"
                                    value="yes"
                                    id="roomRent"
                                    onChange={plansCheckHandler}
                                    ref={roomRentRef}
                                    disabled={isEmpLess100}
                                    defaultChecked={
                                    props?.addon?.roomRent === "yes" && (!isEmpLess100) ? true : false
                                    }
                                />
                                <label
                                    className="form-check-label border-0 fs-14"
                                    htmlFor="roomRent"
                                    style={{ fontWeight: '550' ,color:'#344054'}}
                                >
                                    Room Rent
                                </label>
                            </Stack>
                             {props?.addon?.roomRent === "yes" && 
                            <select
                                className="formoutlin-select  d-block col-6 custom-select "
                                aria-label="Room Rent"
                                ref={roomRentValueRef}
                                onChange={plansCheckHandler}
                                disabled={isEmpLess100}
                                defaultValue={props?.addon?.roomRentValue||1}
                            >
                                {roomRentvalues.map((each, index) => { return <option className='custom-option-mui' key={index} value={each.value}>{each.name}</option> })}
                            </select>}
                        </Stack>
                    </>}
                        <Stack className="quote-checkbx" gap={'12px'} flexDirection={'row'} justifyContent={'space-between'} minHeight={'50px'} padding={'10px 0px'}>
                            <Stack gap={'4px'} flexDirection={'row'} alignItems={'center'}>
                                <input
                                    type="checkbox"
                                    name="coPayRef"
                                    value="yes"
                                    id="coPayRef"
                                    onChange={plansCheckHandler}
                                    ref={coPayRef}
                                    defaultChecked={
                                    props?.addon?.coPay === "yes" ? true : false
                                    }
                                />
                                <label
                                    className="form-check-label border-0 fs-14"
                                    htmlFor="healthcheck"
                                    style={{ fontWeight: '550' ,color:'#344054'}}
                                >
                                    Co-Pay
                                </label>
                            </Stack>
                            {props?.addon?.coPay === "yes" && 
                            <select
                                className="formoutlin-select  d-block col-6 custom-select "
                                aria-label="Health Check Up"
                                ref={coPayRefValues}
                                onChange={plansCheckHandler}
                                defaultValue={props?.addon?.coPayValues||10}
                            >
                                {coPayValues.map((each, index) => { return <option className='custom-option-mui' key={index} value={each.value}>{each.name}</option> })}
                            </select>
                            }
                        </Stack>
                        <Divider/>

                    <Stack className="quote-checkbx" flexDirection={'column'} alignItems={'start'} padding={'10px 0px'} gap={'20px'} minHeight={'50px'}>
                  <Typography fontWeight={500} fontSize={'14px'} color={'#344054'}>Network Hospital Type</Typography>
                    <Stack flexDirection={'row'} gap={'4px'}>
                        <input
                            type="radio"
                            name="netWorkTreatmentType"
                            value={NetworkHospitalTreatment["NETWORK_HOSPITAL_TREATMENT"]}
                            id="netWorkTreatment"
                            onChange={plansCheckHandler}
                            ref={networkHospitalRef3}
                            defaultChecked={props?.addon?.networkHospitalTreatment === NetworkHospitalTreatment["NETWORK_HOSPITAL_TREATMENT"] ? true : false}
                        />
                        <label
                            className="form-check-label border-0 fs-14"
                            htmlFor="netWorkTreatment"
                            style={{ fontWeight: '550' ,color:'#344054'}}
                        >
                            Network Hospital Treatment
                        </label>
                    </Stack>
                    <Stack flexDirection={'row'} gap={'4px'}>
                        <input
                            type="radio"
                            name="netWorkTreatmentType"
                            value={NetworkHospitalTreatment["NON_NETWORK_HOSPITAL_TREATMENT"]}
                            id="netWorkTreatmentType"
                            onChange={plansCheckHandler}
                            ref={networkHospitalRef1}
                            defaultChecked={props?.addon?.networkHospitalTreatment === NetworkHospitalTreatment["NON_NETWORK_HOSPITAL_TREATMENT"] ? true : false}
                        />
                        <label
                            className="form-check-label border-0 fs-14"
                            htmlFor="onlyPreferred"
                            style={{ fontWeight: '550' ,color:'#344054'}}
                        >
                         Non-Network Hospital Treatment
                        </label>
                    </Stack>
                    <Stack flexDirection={'row'} gap={'4px'}>
                        <input
                            type="radio"
                            name="netWorkTreatmentType"
                            value={NetworkHospitalTreatment["ONLY_PRATHAM_HOSPITAL_TREATMENT"]}
                            id="netWorkTreatmentType"
                            onChange={plansCheckHandler}
                            ref={networkHospitalRef2}
                            defaultChecked={props?.addon?.networkHospitalTreatment === NetworkHospitalTreatment["ONLY_PRATHAM_HOSPITAL_TREATMENT"] ? true : false}
                        />
                        <label
                            className="form-check-label border-0 fs-14"
                            htmlFor="onlyPreferred"
                            style={{ fontWeight: '550' ,color:'#344054'}}
                        >
                           Only Preferred Hospitals
                        </label>
                    </Stack>
                    </Stack>
                </Box>
            </Box>
        </Grid>}
        <Grid item xs={12} md={6}>
            <Box
                sx={{
                    background: '#ffffff',
                    border: '1px solid #EAECF0',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 6px -2px #10182808',
                    padding: '16px'
                }}
            >
                <Stack
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                >
                    <Stack marginBottom={'8px'}>
                        <Typography color={'#475467'} fontSize={'18px'} fontWeight={600}>{getTopCardHeader()}{customAddition()}</Typography>
                            {loader ?
                                <Box height={57}><Icon icon="eos-icons:three-dots-loading" width="100px" height="57px" style={{ color: 'rgb(54, 73, 138)' }}></Icon> </Box>
                                : <Typography>
                                    <span style={{ fontSize: '38px' }}>
                                        <strong>
                                            <NumberFormat
                                                thousandsGroupStyle="lakh"
                                                value={props.premium}
                                                prefix="₹"
                                                suffix="*"
                                                decimalSeparator="."
                                                displayType="text"
                                                type="text"
                                                thousandSeparator={true}
                                                allowNegative={true}
                                            />
                                        </strong>
                                    </span>
                                    <small style={{color:'#475467'}} className="fs-14">/ year</small>
                                </Typography>}
                    </Stack>
                    <Typography sx={{ padding: '4px', height: 'fit-content', background: '#F2F6FC', border: '1px solid #CBDCF2' }} borderRadius={'16px'} fontSize={'12px'} color={'#415EB4'}>
                                           <NumberFormat
                                                thousandsGroupStyle="lakh"
                                                value={(props.premium && props.totalEmp) ? Math.floor(props.premium / props.totalEmp):''}
                                                prefix="₹"
                                                suffix="per life"
                                                decimalSeparator="."
                                                displayType="text"
                                                type="text"
                                                thousandSeparator={true}
                                            />
                    </Typography>
                </Stack>
                <Box margin={'10px 0px'}>
                {props.type==='base'?
                            <Typography  
                                color={'#B54708'} 
                                border={'1px solid #FEDF89'} 
                                sx={{
                                    backgroundColor:'#FFFAEB'
                                }}
                                fontSize={'12px'}
                                borderRadius={'16px'}   
                                padding={'6px'}
                            >
                                All the terms and conditions applicable will be carried forward from previous expiring policy.
                            </Typography>
                            :<Button variant={'contained'} sx={{width:'fit-content', justifyContent:'start', fontSize: '14px' ,fontWeight:'600',padding:'4px 8px',bgcolor:'#36498A',color:'#fff',borderRadius:'8px'}} endIcon={<Icon icon="ri:arrow-right-line" width="20px" height="20px" style={{ color: '#fff' }} />} onClick={() => {
                                if (props.leadType !== 'market_renewal' && props.leadType !== 'star_renewal') {
                                    props.showBenefits({
                                      isMaternity:props.tag === "Maternity",
                                      isCBuffer:(cbufferRef && cbufferRef.current && cbufferRef.current.checked)?true:false,
                                      isOPD:(opdRef && opdRef.current && opdRef.current.checked)?true:false,
                                      isHealth:(healthCheckRef && healthCheckRef.current && healthCheckRef.current.checked)?true:false,
                                      isOnAddOnMaternity:(maternityRef && maternityRef.current && maternityRef.current.checked)?true:false,
                                    })
                                }
                                return
                            }}>{(props.leadType !== 'market_renewal' && props.leadType !== 'star_renewal') ? 'View Benefits' : 'Benefits'}
                            </Button>}
                            </Box>
                <Divider />
                <Stack>
                    <Stack justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} margin={'10px 0px'} borderRadius={'8px'}>
                        <Stack alignItems={'start'}>
                            <Typography fontWeight={600} fontSize={'14px'}>Discount</Typography>
                            <Typography fontWeight={400} fontSize={'12px'} color={'#666666'}>Range {props?.discountDetails?.minCommission || 0}-{props?.discountDetails?.maxCommission || 0}%</Typography>
                        </Stack>
                        <TextField
                            variant="standard"
                            size="small"
                            sx={{
                                width: '200px',
                                border:'1px solid #D0D5DD',
                                background: '#ffffff',
                                borderRadius: '8px', '& input': {
                                    padding: '10px'
                                }
                            }}
                              value={discountAdded}
                              onChange={(e)=>
                                  handleDiscountChange(e.target.value)
                              }
                            InputProps={{
                                endAdornment: <InputAdornment position="end" sx={{ padding: '8px' }}>%</InputAdornment>,
                                disableUnderline: true,
                            }}
                        />
                    </Stack>
                    <Grid container padding={'10px 0px'} justifyContent={'space-between'} flexWrap={'wrap'}>
                    {!!props?.discountDetails?.bankCommission && <Grid item  textAlign={'start'}>
                            {loader ?
                                <Box height={24}>
                                    <Icon icon="eos-icons:three-dots-loading" width="30px" height="24px"  style={{color: 'rgb(54, 73, 138)'}}></Icon>
                                </Box>: 
                                <Typography fontSize={'16px'} fontWeight={'600'}>{props?.discountDetails?.bankCommission||0}%</Typography>
                                }
                            <Typography fontSize={'13px'} color={'#808080'}>Bank Commission</Typography>
                        </Grid>}
                        <Grid item textAlign={'start'} >
                        {loader ? <Box height={24}><Icon icon="eos-icons:three-dots-loading" width="30px" height="24px" style={{ color: 'rgb(54, 73, 138)' }}></Icon></Box> : <Typography fontSize={'16px'} fontWeight={'600'}>{props?.discountDetails?.commission||0}%</Typography>}
                            <Typography fontSize={'13px'} color={'#808080'}>Marketing Fee</Typography>       
                        </Grid>
                        <Grid item textAlign={'start'}>
                        {loader ? <Box height={24}><Icon icon="eos-icons:three-dots-loading" width="30px" height="24px" style={{ color: 'rgb(54, 73, 138)' }}></Icon></Box> : <Typography fontSize={'16px'} fontWeight={'600'}>₹ {props.earning}</Typography>}
                            <Typography fontSize={'13px'} color={'#808080'}>My Earnings</Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Divider />
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} padding={'10px 20px'} gap="12px">
                    <Button
                        onClick={handleEmailShare} 
                        sx={{ width: '-webkit-fill-available', height: '43px' }} variant="contained" startIcon={<Icon icon="ic:outline-share" width="20px" height="20px" style={{ color: '#ffffff' }} />}>Share Quote</Button>
                    <Button
                        onClick={downloadPDf} 
                        sx={{ width: '43px', height: '43px', minWidth: '0px', padding: '5px', borderColor: '#rgba(0, 0, 0, 0.12) !important' }} variant="outlined"><Icon icon="tabler:cloud-download" width="20px" height="20px" fontWeight={700} /></Button>
                </Stack>
            </Box>
        </Grid>
    </Grid>
    </>
}

export default NewQuoteCard