import React, { useEffect } from "react";
import { getCookie } from "./utils"
import axios from "axios";
import { API_BASE_URL } from "./config";
import moment from 'moment';
import { setCookie } from "./utils";
import { useNavigate } from 'react-router-dom';

const AuthGuard = ({ children, sessionHandler }) => {
  const voAuthToken = getCookie('vo-auth-token')
  const agent = getCookie('vo-login-id')
  const leadDetails = sessionStorage.getItem("leadDetails") 
  ? JSON.parse(sessionStorage.getItem("leadDetails")) 
  : null;
  const urlParams = new URLSearchParams(window.location.search);
  const authTokenValue = urlParams.get('token');
  const agentValue = urlParams.get('agent')
  const agentUserId = urlParams.get('userId')
  const navigate = useNavigate();

  useEffect(() => {
    const removeQueryParamAndRedirect = () => {
      const { pathname } = window.location;
      const newUrl = pathname;
      navigate(newUrl, { replace: true });
    }

    if (authTokenValue && agentValue && agentUserId) {
      const tokenExpiryTime = moment().add(1, "months");
      const cookieOptions = {
        path: '/',
        expires: new Date(tokenExpiryTime.format()),
        domain: '.starhealth.in',
        secure: false
      };
      setCookie('vo-auth-token', authTokenValue, cookieOptions);
      setCookie('vo-login-id', agentValue, cookieOptions);
      setCookie('vo-userId', agentUserId, cookieOptions);
      removeQueryParamAndRedirect();
    }
  }, [])

  useEffect(() => {
    //sessionStorage.setItem("insureDetails", JSON.stringify({}));
    if (!(voAuthToken && agent)) {
      if(authTokenValue && agentUserId){
        return
      }
      else{
        sessionHandler()
        localStorage.clear()
        sessionStorage.clear()
      }
      
    }
  }, [])

  useEffect(() => {
    if (voAuthToken && agent) {
      axios
        .get(`${API_BASE_URL}/sme-api/auth/getUserDetails`,{
          headers: {
            user:agent,
            token: voAuthToken,
          },
        })
        .then(({ data }) => {
          console.log(data, 'usertype')
          data && sessionStorage.setItem("userType", data.userType);
        })
        .catch((error) => { 
          if (error.response && error.response.status === 401) {
            console.error('Bad Request:', error.response.data);
            sessionHandler()
          }
        })
    }
  }, [voAuthToken,agent])

  useEffect(()=>{
    if(!(leadDetails?.leadId)&& window.location.pathname ==='/quote'){
      navigate('/company-details')}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      {children}
    </>
  )
}

export default AuthGuard