import React, { useState } from "react";
import { useFormik, FormikProvider ,Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Webloader ,estimateSizeInBytes,removeEmptyValues} from "../../utils";
import UserDetails from "../formSteps/UserDetails";
import InsuranceDetails from "../formSteps/InsuranceDetails";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import { branch } from "../../utils/branch";
import { getCookie } from "../../utils";
import useSnackBars from "../common/snackbar/SmeSnackbar";

const countType = Yup.number().min(0, "Min count should be 0");

const LeadForm = ({ initialValues ,sessionHandler}) => {
  const [step, setStep] = useState(0);
  const [policyStatus,setPolicyStatus] = useState('initial')
  const MAX_SIZE_MB = 5;
  const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; 
  const agent = getCookie('vo-login-id')
  const { addAlert } = useSnackBars() || {};
  const insureDetails = JSON.parse(sessionStorage.getItem("insureDetails"))
  const navigate = useNavigate();
  const [userType] = useState(
    sessionStorage.getItem("userType")
      ? sessionStorage.getItem("userType")
      : false
  );
  const [brokerPincode] = useState(
    sessionStorage.getItem("brokerPincode")
      ? sessionStorage.getItem("brokerPincode")
      : ""
  );
  const voAuthToken = getCookie('vo-auth-token')

  const handleLeadDetails = (values) => {
    let updatedInsureDetails = { ...insureDetails,leadDetails:values}

    const dataSize = estimateSizeInBytes(updatedInsureDetails);
    if (dataSize >= MAX_SIZE_BYTES ) {
      if(updatedInsureDetails && updatedInsureDetails.leadDetails && updatedInsureDetails.leadDetails.marketRenewalDoc){
            sessionStorage.setItem("insureDetails",JSON.stringify(
                {...updatedInsureDetails,
                leadDetails:{...updatedInsureDetails.leadDetails,
                marketRenewalDoc:{}
              }}));
              }
    }
    else
    sessionStorage.setItem("insureDetails",JSON.stringify(updatedInsureDetails));
  }

  function isObject(value) {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }

  const saveForm = (values) => {
    Webloader("show");
    values= {...values,
      familyDetails:removeEmptyValues(values.familyDetails),
      marketRenewal:{...values.marketRenewal, 
          policyPeriodTo: new Date(values.marketRenewal.policyPeriodTo),
          policyPeriodFrom:new Date(values.marketRenewal.policyPeriodFrom), 
          recievedDateOfClaimData:new Date(values.marketRenewal.recievedDateOfClaimData),
          totalClaim: values.marketRenewal.claimPaid+values.marketRenewal.claimOutstanding
      },
    }


    // const sanitizeData = (data) => {
    //   if (data.policyType === 'star_renewal') {
    //     const { ...rest } = data.starRenewal;
    //     data.starRenewal = rest; // Remove `existingTPAOthers`
    //   }
    //   return data;
    // };

    const {agreeToTerms, ...apiValues} = values

    axios
      .post(`${API_BASE_URL}/sme-api/lead`, apiValues, {
        headers: {
          user: agent,
          token:voAuthToken,
        },
      })
      .then((response) => {
        if(response.data && response.data.data){
          sessionStorage.setItem("leadDetails", JSON.stringify(response.data.data))
          handleLeadDetails(formik.values)
          navigate("/quote");
        }
        else{
          addAlert("Something Went Wrong !!!");
        }
        Webloader()
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          sessionHandler()
          console.error('Bad Request:', error.response.data);
        }
        else if(error.response && error.response.status === 500){
          const docDetails = error.response && error.response.data && error.response.data.data && error.response.data.data.marketRenewalDoc ||{}
          if( values.policyType==='market_renewal'){
          if(!docDetails?.claimDump?.isUploaded){
            addAlert("Claim Files Upload Failed")
            Webloader()
            return
          }
          if(!docDetails?.claimPdf?.isUploaded){
            addAlert("Claim Files Upload Failed")
            Webloader()
            return 
          }
        }
      }
        else
        addAlert("Something Went Wrong !!!");
        Webloader()
      });
  };

  const SUPPORTED_FORMATS = ['pdf', 'xlsx','csv', 'xls'];

  const validPolicy =() =>{
    return policyStatus!=='success'
  }

  const commonFileds = {
  companyId: Yup.string().required('Company Id is required'),
    policyType: Yup.string().required('Policy type is required'),
    marketRenewal: Yup.object().when('policyType', {
      is: 'market_renewal', // Replace with the specific value you want to check against
      then: Yup.object().shape({
        insurerName: Yup.string().required('Previous Insurer Name is required'),
        expiringPolicyNo: Yup.string().required('Existing Policy No. is required'),
        existingTPA: Yup.string().required('Existing TPA is required'),
        policyPeriodFrom: Yup.string().required('Policy Period start date  is required'),
        liveAtInception: Yup.string().required('Lives At Inception is required'),
        currentLives: Yup.string().required('Current Lives is required'),
        claimPaid:Yup.string().required('Claim Paid is required'),
        expiringPolicyPremium:Yup.string().required('Policy Premium is required'),
        recievedDateOfClaimData:Yup.string().required('Recieved Claim Date is Required'),
        claimOutstanding:Yup.string().required('Claim Outstanding is Required'),
      }),
      otherwise: Yup.object().shape({}),
    }),
    starRenewal: Yup.object().when('policyType', {
      is: 'star_renewal',
      then: Yup.object().shape({
        existingTPA: Yup.string().required('Existing TPA is required'),
        expiringPolicyNo: Yup.string().required('Existing Policy no is Required')
        .test('valid-domain', 'Please Validate the Policy Number', function() {
          return !validPolicy();
        })
      })
    }),
    marketRenewalDoc:Yup.object().when('policyType', {
      is: 'market_renewal',
      then: Yup.object().shape({
        claimPdf: Yup.mixed().required('Claim PDF is required')
        .test(
          'fileFormat',
          'Unsupported file format. Please upload a PDF file.',
          (value) => value && [SUPPORTED_FORMATS[0]].includes(value.documentType)
        ),
        claimDump:Yup.mixed().required('Claim Dump is required')
        .test(
          'fileFormat',
          'Unsupported file format. Please upload a PDF ,XLSX and csv file.',
          (value) => {
            return value && SUPPORTED_FORMATS.includes(value.documentType)
          }
        )
      }),
      otherwise: Yup.object().shape({}),
    }),
    agreeToTerms: Yup.boolean().when('policyType',{
      is: 'market_renewal',
      then:Yup.boolean()
      .oneOf([true], 'You must accept the terms and conditions.')
      .required('You must accept the terms and conditions.'),
      otherwise: Yup.boolean(),
    }),

    sumInsured: Yup.array()
      .test(
        "len",
        "Select atleast one insurance amount",
        (val) => val && val.length > 0
      )
      .required("Please select the sum insured value"),
    familyDetails: Yup.object().when("sumInsured", (sumInsured, schema) => {
      return  sumInsured
        .reduce(
          (object, sum) =>
            object.shape({
              [sum]: Yup.object().shape({
                child: countType,
                emp18: countType,
                emp36: countType,
                emp46: countType,
                emp56: countType,
                emp: countType,
                inlaws: countType,
                parents: countType,
                inlaws18: countType,
                inlaws36: countType,
                inlaws46: countType,
                inlaws56: countType,
                parents18: countType,
                parents36: countType,
                parents46: countType,
                parents56: countType,
                spouse18: countType,
                spouse36: countType,
                spouse46: countType,
                spouse56: countType,
                spouse: countType,
              }),
            }),
          Yup.object()
        )
        .test("Required", "Details Are Required For Selected Sum Insured",(familyDetails) => {
          for (const [plan, peopleCountUnderPremium] of Object.entries(familyDetails)) {
            if (peopleCountUnderPremium && Object.values(peopleCountUnderPremium).every(value => value === undefined)) {
              return false
            }
          }
          return true
          })
        .test("min", "Total value must be greater than 25", (familyDetails) => {
          let peopleCount = 0;
          Object.values(familyDetails).forEach((peopleCountUnderPremium) => {
            peopleCount += Object.values(peopleCountUnderPremium).reduce(
              (a, b) => +a + +b,
              0
            );
          });

          return peopleCount >= 25;
        })
        .test("max", "Total value must be less than 500", (familyDetails) => {
          let peopleCount = 0;
          Object.values(familyDetails).forEach((peopleCountUnderPremium) => {
            peopleCount += Object.values(peopleCountUnderPremium).reduce(
              (a, b) => +a + +b,
              0
            );
          });
          return peopleCount <= 500;
        })
        .test(
          "min",
          "Total value must be greater than 100",
          (familyDetails) => {
            let peopleCount = 0;
            let parentCount = 0;
            Object.values(familyDetails).forEach((peopleCountUnderPremium) => {
              peopleCount += Object.values(peopleCountUnderPremium).reduce(
                (a, b) => +a + +b,
                0
              );
              Object.keys(peopleCountUnderPremium).forEach((type, index) => {
                if (
                  type.indexOf("parent") > -1 &&
                  peopleCountUnderPremium[type] > 0
                ) {
                  parentCount += 1;
                }
              });
            });
            if (parentCount > 0) return peopleCount >= 100;
            else return peopleCount >= 25;
          }
        )
        .test(
          "min",
          "Total value must be greater than 100",
          (familyDetails) => {
            let peopleCount = 0;
            let parentCount = 0;
            Object.values(familyDetails).forEach((peopleCountUnderPremium) => {
              peopleCount += Object.values(peopleCountUnderPremium).reduce(
                (a, b) => +a + +b,
                0
              );
              Object.keys(peopleCountUnderPremium).forEach((type, index) => {
                if (
                  type.indexOf("inlaws") > -1 &&
                  peopleCountUnderPremium[type] > 0
                ) {
                  parentCount += 1;
                }
              });
            });
            if (parentCount > 0) return peopleCount >= 100;
            else return peopleCount >= 25;
          }
        ).
        test(
          "countmax",
          "Regret, total number of parents/in laws count should be equal to or more than 30% of the total number of employees to allow the quote creation",
          (familyDetails) => {
            let parentsSum = 0
            let empSum = 0

            Object.values(familyDetails).forEach((peopleCountUnderPremium) => {
              parentsSum += (peopleCountUnderPremium.parents || 0) + (peopleCountUnderPremium.inlaws || 0)
              empSum += (peopleCountUnderPremium.emp || 0) + (peopleCountUnderPremium.emp18 || 0)
            })

            if (parentsSum === 0) return true
            else if (empSum * 0.30 <= parentsSum)
              return true
            else
              return false
          }
        );
    }),
  };

  const commonValidationSchema = Yup.object().shape({
    ...commonFileds,
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      saveForm(values);
    },
    validationSchema : commonValidationSchema
  });

  // const prevStep = () => {
  //   formik.setErrors({});
  //   setStep(step - 1);
  // };

  const nextStep = async () => {
    const errors = await formik.validateForm();
    let filterFamilyDetails = {};
    await formik.values.sumInsured.forEach((value, index) => {
      filterFamilyDetails[value] = formik.values.familyDetails[value];
    });
    formik.values.familyDetails = filterFamilyDetails;
    formik.setErrors(errors);

    const errorField =  Object.keys(errors)
    for (let i = 0; i < errorField.length; i++) {
      if(isObject(errors[errorField[i]])){ 
        const insideErrorKeys = Object.keys(errors[errorField[i]])
        for (let j = 0; j < insideErrorKeys.length; j++) {
         formik.setFieldTouched(`${errorField[i]}.${insideErrorKeys[j]}`);
        }
      }
        else
        formik.setFieldTouched(errorField[i]);
    }
    if (Object.keys(errors).length>0) {
      return;
    }
    formik.setErrors({});
    //handleLeadDetails(formik.values)
    saveForm(formik.values)
  };

  const pages = [
    <InsuranceDetails
      nextStep={nextStep}
      values={formik.values}
      errors={formik.errors}
      policyStatus={policyStatus}
      setPolicyStatus={setPolicyStatus}
      formikData={formik}
      branch={branch}
    />,
    <UserDetails
      nextStep={nextStep}
      values={formik.values}
      errors={formik.errors}
    />,
  ];

  const getStep = () => (step < pages.length ? pages[step] : null);

  return (
    <FormikProvider value={formik}>
      <form
        className="needs-validation"
        noValidate
        action="#"
        onSubmit={formik.handleSubmit}
      >
        {getStep()}
      </form>
    </FormikProvider>
  );
};

export default LeadForm;
