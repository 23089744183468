export const DASBHBOARD_BLUE_COLOR='#3194FD';

export type enumsLabelType={[key:string]:string};


export const enum PolicyType {
    MARKET_RENEWAL = "market_renewal",
    FRESH = 'fresh',
    STAR_RENEWAL = 'star_renewal'
}

export const enum FamilyDetailAge {
    emp='emp',
    emp18 = 'emp18',
    emp36 = 'emp36',
    emp46 = 'emp46',
    emp56 = 'emp56',
    mother='mother',
    spouse='spouse',
    inlaws='inlaws',
    child='child',
    parents='parents'
}

export const enum SORTBYLEADS {
    ASC_DATE = 'asc_date',
    DES_DATE = 'des_date',
}

export const enum LeadStatus {
    DRAFT_SAVED = 'DRAFT_SAVED',
    LEAD_GENERATED = 'LEAD_GENERATED',
    QUOTE_DOWNLOADED = 'QUOTE_DOWNLOADED',
    QUOTE_SHARED = 'QUOTE_SHARED',
    PAYMENT_INITIATED = 'PAYMENT_INITIATED',
    PAYMENT_DONE = 'PAYMENT_DONE',
    QUOTE_FAILED_ICR = "QUOTE_FAILED_ICR"
}


export const PolicyTypeLabel:enumsLabelType = {
    [PolicyType.MARKET_RENEWAL]: 'Market Renewal',
    [PolicyType.FRESH]: 'Fresh',
    [PolicyType.STAR_RENEWAL]: 'Star Renewal'
}

export const LeadStatusLabel = {
    [LeadStatus.DRAFT_SAVED]: 'Draft Saved',
    [LeadStatus.LEAD_GENERATED]: 'Lead Generated',
    [LeadStatus.PAYMENT_DONE]: 'Payment Done',
    [LeadStatus.PAYMENT_INITIATED]: 'Payment Initiated',
    [LeadStatus.QUOTE_DOWNLOADED]: 'Quote Downloaded',
    [LeadStatus.QUOTE_SHARED]: 'Quote Shared',
    [LeadStatus.QUOTE_FAILED_ICR]:'Quote Failed (ICR)'
}

export const SortByLeadsLabels={
    [SORTBYLEADS.DES_DATE]:'Date (Latest Created)',
    [SORTBYLEADS.ASC_DATE]:'Date (Oldest Created)'
    
}

export enum QuoteType {
    BASE = 'base',
    MATERNITY = 'maternity',
    CUSTOMIZED = 'customized',
}

export enum DocumentType {
    claimPdf='claimPdf',
    claimDump='claimDump'
}

export const DocumentTypeLabel={
    [DocumentType.claimDump]:'Claim Dump/PDF',
    [DocumentType.claimPdf]:'Additional Document'
}
