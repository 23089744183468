import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { FieldErrorMessage } from "../common/FieldErrorMessage";
import NumberInput from "../common/NumberInput";
import './companyDetails.css'
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack"
import { CompanyDetailApi } from "./companyDetails.service";
import { Autocomplete } from "@mui/material";
import useSnackBars from "../common/snackbar/SmeSnackbar";
import { getCookie } from "../../utils";
import useDebounce from "../common/hooks/useDebounce";

const CompanyDetailsForm = ({ showFlag, setShowFlag }) => {

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  // const loading = open && options.length === 0;

  const voAuthToken = getCookie('vo-auth-token')
  const agent = getCookie('vo-login-id');

  const { addAlert } = useSnackBars() || {};

  const [industry] = useState([
    "IT/BPO",
    "Banking/Finance",
    "Consultants & Advisors",
    "Media/Advertising",
    "Logistics/Transport",
    "Hospital",
    "Food/ Shop & Hospitality",
    "Chemical & Pharma",
    "Education",
    "Govt Sector",
    "Manufacturing",
  ]);

  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext();
  const [loading,setLoading]=useState(false);
  const [searchValue, setSearchValue] = useState('');

  const debounceSearchValue=useDebounce({ value: searchValue, delay: 1000 })

  const searchCompany= async (company)=>{
    if(!company){
      return [];
    }
    setLoading(true);
    try {
      
        const res = await CompanyDetailApi.getCompanyList('company', company, 'infix', voAuthToken, agent); // For demo purposes.
        const data=res?.data?.data?.searchResult;
        setOptions(data??[]); 
        if(!data?.length){
          setFieldValue('companyName',company)
        }
        
      } catch (e) {
        console.log(e.response, e);
        if (e?.response?.status === 500) {
          addAlert('Internal Server Error (500)')
        }
        return [];
      }
      setLoading(false);
    }

    useEffect(() => {
      if (debounceSearchValue.trim() !== '') {
        searchCompany(debounceSearchValue);
      }
    }, [debounceSearchValue])
  

  // useEffect(() => {
  //   let active = true;

  //   if (!loading) {
  //     return undefined;
  //   }
  //   searchCompany();
  //   return () => {
  //     active = false;
  //   };
  // }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <main className="row MainPage mx-auto mt-3">
      <Container>
        <div className="FormSection bg-white p-3">
          <div className='info-tooltip'>
            <img
              src="/new-req/information-fill.svg"
              alt='info'
            />
            <div>Only Employer - Employee is selected for others please contact
              technical department for quotation</div>
          </div>
          {showFlag ? (
            <>
              <form action="#">
                <div className='row mx-auto'>
                  <div className="form-group mb-4 col-12 col-sm-6">
                  <Autocomplete 
                      open={open}
                      onOpen={(a) => {
                        setOpen(true);
                      }}
                      onClose={(a) => {
                        setOpen(false);
                      }}
                      value={values.companyName}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      options={options}
                      onBlur={(a)=>{
                        if(a.target.value){
                          setFieldValue('companyName',a.target.value)
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setSearchValue(newInputValue);
                      }}
                      id="companyName"
                      name="companyName"
                      freeSolo={true}
                      onChange={(e,value)=>setFieldValue('companyName',value)}
                      loading={loading}
                      noOptionsText={' No Company Available'}
                      filterOptions={(x,y) => x}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          placeholder="Corporate Name"
                          value={values.companyName}
                           label="Corporate Name"
                          onBlur={handleBlur}
                          required
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment} */}
                                </React.Fragment>
                              ),
                            },
                          }}
                        />


                      )}
                    />

                    <FieldErrorMessage name="companyName" />
                  </div>
                  <div className="col-12 col-sm-6 mb-4">
                    <TextField
                      variant="outlined"
                      label="Corporate Industry"
                      fullWidth
                      select
                      id="industry"
                      name="industry"
                      value={values.industry}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    >
                      {industry.map((occ, index) => (
                        <MenuItem key={`occ-${index}`} value={occ}>
                          {occ}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FieldErrorMessage name="industry" />
                  </div>
                </div>
                <div className='row mx-auto'>
                  <div className="form-group mb-4 col-12 col-sm-6">
                    <TextField
                      variant="outlined"
                      label="Corporate Address"
                      fullWidth
                      id="address"
                      name="address"
                      placeholder="Corporate Address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <FieldErrorMessage name="address" />
                  </div>
                  <div className="form-group mb-4 col-12 col-sm-6">
                    {/* <label className="FormLabel fs-14">
                      Corporate Pincode <span className="mandatory">*</span>
                    </label> */}
                    <NumberInput
                      name="pincode"
                      placeholder="Corporate Pincode"
                      maxlen="6"
                      className='form-control'
                      currentValue={values.pincode}
                      onChange={(e) => setFieldValue("pincode", e.target.value)}
                      onBlur={handleBlur}
                      required={true}
                    />
                    <FieldErrorMessage name="pincode" />
                  </div>
                </div>
                <div className='row mx-auto'>
                  <div className="form-group mb-4 col-12 col-sm-6">
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Contact Person Name"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <FieldErrorMessage name="name" />
                  </div>
                  <div className="form-group mb-4 col-12 col-sm-6">
                    <TextField
                      label="Contact Person Email ID"
                      variant="outlined"
                      fullWidth
                      id="emailId"
                      name="emailId"
                      placeholder="Email ID"
                      value={values.emailId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <FieldErrorMessage name="emailId" />
                  </div>
                </div>
                <div className="form-group mb-4 col-12 col-sm-6">
                  {/* <label className="FormLabel fs-14">
                    Contact Person Phone no<span className="mandatory">*</span>
                  </label> */}
                  <NumberInput
                    name="phoneNo"
                    placeholder="Contact Person Phone no"
                    maxlen="10"
                    className="form-control"
                    currentValue={values.phoneNo}
                    onChange={(e) => setFieldValue("phoneNo", e.target.value)}
                    onBlur={handleBlur}
                    required={true}
                  />
                  <FieldErrorMessage name="phoneNo" />
                </div>
              </form>
            </>
          ) : (
            <Stack alignItems={'center'}>
              <h5 className="fs-16 text-center">
                Kindly contact to corporate office technical team.
              </h5>
              <Button variant="contained" onClick={() => setShowFlag(true)}>Back</Button>
            </Stack>
          )}
        </div>
        <div className="warningBox">
          <Typography padding={'6px'} fontSize={'14px'} fontWeight={600} color={'#ff0000'}>
            The quote will be given with the condition that all employees and
            dependents are covered without any selection
          </Typography>
        </div>
      </Container>
    </main>
  );
};

export default CompanyDetailsForm;
